import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styles from './Form.module.scss';

export default class Form extends React.Component {

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
    };
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault()
    const result = await addToMailchimp(this.state.email);
    if (result) {
      this.setState({
        email: '',
      });
    }
  }

  render() {
    return (
      <form className={styles['form']} onSubmit={this.handleSubmit}>
        <label>
          Never miss the latest!
          <br/>
          <input
            type='text'
            name='email'
            value={this.state.email}
            placeholder={'Email address'}
            onChange={this.handleInputChange}
          />
        </label>
        <button style={{cursor: 'pointer'}} type='submit'>Subscribe</button>
      </form>
    )
  }
}
